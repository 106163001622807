import { useSessionStore } from '@/stores/session'
import { UserRole } from '@/utils/constants'

export const hasAccess = (role: UserRole | UserRole[], userId?: string): boolean => {
  if (!role || role.length === 0) {
    return false
  }

  const sessionStore = useSessionStore()
  const user = sessionStore.getUser()
  const rolesToCheck = Array.isArray(role) ? role : [role]
  return !!user && (rolesToCheck.some((r) => r == UserRole.ALL || user?.roles.includes(r)) || user?.id === userId)
}

export const hasAccessMixin = {
  methods: {
    hasAccess
  }
}

export const hasAccessDirective = {
  mounted(el: any, binding: any) {
    if (!hasAccess(binding.value)) {
      el.style.display = 'none'
    }
  },

  updated(el: any, binding: any) {
    if (!hasAccess(binding.value)) {
      el.style.display = 'none'
    } else {
      el.style.display = ''
    }
  }
}
