import { doRequest, REQUEST_METHODS } from './base'

export const getSetting = async (id: string): Promise<SettingsResponse> => {
  return await doRequest(REQUEST_METHODS.GET, `/api/settings/${id}`)
}

export const getSettings = async (): Promise<AppSettingsResponse> => {
  return await doRequest(REQUEST_METHODS.GET, `/api/users/settings`)
}

export const createSetting = async (key: string, value: string, apps: string[]): Promise<SaveSettingsResponse> => {
  return await doRequest(REQUEST_METHODS.POST, `/api/users/settings`, { key, value, apps })
}

export const updateSetting = async (
  id: string,
  key: string,
  value: string,
  apps: string[]
): Promise<SaveSettingsResponse> => {
  return await doRequest(REQUEST_METHODS.PATCH, `/api/users/settings/${id}`, { key, value, apps })
}

export const getPublicSettings = async (app: string): Promise<SettingsResponse> => {
  return await doRequest(REQUEST_METHODS.GET, `/api/settings/${app}`)
}

export default {
  getSetting,
  getSettings,
  createSetting,
  updateSetting,
  getPublicSettings
}
