function getRootDomain() {
  return window.location.hostname.split('.').reverse().splice(0, 2).reverse().join('.')
}

export function setCookie(name: string, value: string, days: number = 7) {
  const expires = new Date()
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000)
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/;domain=.${getRootDomain()};SameSite=Lax;`
}

export function getCookie(name: string) {
  const regex = new RegExp(`(^| )${name}=([^;]+)`)
  const match = document.cookie.match(regex)
  return (match && match[2]) || ''
}

export function deleteCookie(name: string) {
  setCookie(name, '', -7)
}

export default {
  setCookie,
  getCookie,
  deleteCookie
}
