import Honeybadger from '@honeybadger-io/js'

const captureUnhandledRejection = () => {
  window.onunhandledrejection = async (event) => {
    const errorMessage = event.reason?.message || event.reason || 'Unknown error'
    const errorStack = event.reason?.stack || 'No stack trace available'
    const currentUrl = window.location.href

    console.log('[Debug] Unhandled promise rejection', JSON.stringify(event), currentUrl)

    try {
      Honeybadger.notify('Unhandled promise rejection', {
        context: { errorMessage, errorStack, currentUrl, timestamp: new Date().toISOString() }
      })
    } catch (error) {
      console.log('[Debug] Unhandled promise rejection error', error)
    }

    event.preventDefault()
  }
}

export { captureUnhandledRejection }
