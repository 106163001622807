import { ORDER_STATUSES } from '@/utils/constants'

export const capitalizeFirstLetter = (text: string): string => {
  if (text && text.toString().length > 0) {
    return text.charAt(0).toUpperCase() + text.slice(1)
  } else {
    return ''
  }
}

export const pluralize = (quantity: number, word: string) => {
  if (quantity > 1) {
    return `${word}s`
  } else {
    return word
  }
}

export const copyToClipboard = async (text: string): Promise<boolean> => {
  try {
    await navigator.clipboard.writeText(text)
    return true
  } catch (err) {
    return false
  }
}

export const getOrderStatusColor = (status: string): string => {
  const defaultColor = 'bg-dashboard-yellow'
  if (!status) return defaultColor

  const colorMap: { [key: string]: string } = {
    [ORDER_STATUSES.COMPLETED]: 'bg-dashboard-success-green',
    [ORDER_STATUSES.REFUNDED]: 'bg-dashboard-action-blue',
    [ORDER_STATUSES.CANCELLED]: 'bg-dashboard-pink',
    [ORDER_STATUSES.UNCAPTURED]: 'bg-dashboard-error-red'
  }

  return colorMap[status] || defaultColor
}

export const humanize = (text: string = ''): string => {
  return text
    .replace(/([a-z])([A-Z])/g, '$1 $2') // add space between words in camelCase
    .replace(/[_-]/g, ' ')
}

export const titleCase = (text: string = ''): string => {
  return humanize(text)
    .split(' ')
    .map((word) => capitalizeFirstLetter(word))
    .join(' ')
}

export const formatNumberWithCommas = (string: string): string => {
  return string.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export default {
  pluralize,
  capitalizeFirstLetter,
  copyToClipboard,
  getOrderStatusColor,
  formatNumberWithCommas
}
